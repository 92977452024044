
import DataTable, { Column, Row } from '@/components/DataTable/DataTable.vue'
import Page from '@/components/Page.vue'
import PageTop from '@/components/PageTop.vue'
import { ICountry } from '@/types/countries'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Countries',
  data(): { columns: Column[]} {
    return {
      columns: [
        {
          key: 'title',
          title: 'Titel',
          canSort: true,
        },
        {
          key: 'countryCode',
          title: 'Landekode',
          canSort: true,
        },
        {
          key: 'isActive',
          title: 'Status',
          canSort: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'allCountries',
    ]),
    rows(): Row[] {
      const rows: Row[] = []

      this.allCountries.forEach((country: ICountry) => {
        rows.push({
          title: this.mixWB(country.translation),
          countryCode: country.countryCode,
          isActive: country.isActive,
        })
      })

      return rows
    },
  },
  components: { Page, PageTop, DataTable },
})
